import Dinero from "dinero.js"
import createDispatcher from "./createDispatcher"

const dispatcher = createDispatcher("fbq")

const createItem = ({
  name,
  id,
  price,
  category,
  listName,
  quantity,
  index,
  currency,
  discount
}) => {
  return {
    item_name: name,
    item_id: id,
    price: Dinero({
      amount: price,
      currency: currency
    }).toUnit(),
    item_brand: "COMIS",
    item_category: category,
    ...(listName ? { list_name: listName } : {}),
    ...(index || index === 0 ? { index } : {}),
    ...(quantity || quantity === 0 ? { quantity } : {}),
    discount
  }
}

const getProductDiscount = (cart, productId) =>
  cart.discounts.productDiscounts.find((d) => d.id === productId)
    ?.discountAmount[cart.store.currencyUnit]

export const startSubscriptionPurchase = ({ productName, location }) => {
  const fbq = dispatcher(window.fbq)
  fbq("track", "AddToCart", {
    product_name: productName,
    location
  })
}

export const completeSubscriptionConfig = ({ productName, frequency }) => {
  const fbq = dispatcher(window.fbq)
  fbq("track", "Subscription Step Completed", {
    product_name: productName,
    frequency
  })
}

export const addShipping = ({ cart, shippingOption, deliveryStep }) => {
  const fbq = dispatcher(window.fbq)
  fbq("track", "Shipping added", {
    currency: cart.store.currencyUnit,
    delivery_method: shippingOption.label,
    sek_shipping_fee: Dinero({
      amount: cart?.cartItems?.find((item) => item.type === "shippingOption")
        ?.price["SEK"],
      currency: "SEK"
    }).toUnit(),
    shipping_fee: Dinero({
      amount: cart?.cartItems?.find((item) => item.type === "shippingOption")
        ?.price[cart.store.currencyUnit],
      currency: cart.store.currencyUnit
    }).toUnit(),
    delivery_step: deliveryStep
  })
}

export const initiateCheckout = ({ storedCart }) => {
  const items = storedCart.cartItems.filter(
    (item) => item.type !== "shippingOption"
  )
  const itemsPurchased = items.reduce((acc, item) => (acc += item.quantity), 0)
  const fbq = dispatcher(window.fbq)
  fbq("track", "Checkout Started", {
    currency: storedCart.store.currencyUnit,
    items_purchased: itemsPurchased,
    products: items.map((i) => {
      const productDiscount = getProductDiscount(storedCart, i.id)
      return createItem({
        id: i.id,
        name: i.name,
        brand: "COMIS",
        price: i.price[storedCart.store.currencyUnit],
        discount: productDiscount
          ? Dinero({
              amount: productDiscount,
              currency: storedCart.store.currencyUnit
            }).toUnit()
          : 0,
        category: i.category,
        quantity: i.quantity,
        currency: storedCart.store.currencyUnit
      })
    }),
    subtotal: Dinero({
      amount: items.reduce(
        (acc, item) => (acc += item.price[storedCart.store.currencyUnit]),
        0
      ),
      currency: storedCart.store.currencyUnit
    }).toUnit(),
    sek_subtotal: Dinero({
      amount: items.reduce((acc, item) => (acc += item.price["SEK"]), 0),
      currency: "SEK"
    }).toUnit()
  })
}
