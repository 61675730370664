import * as amplitudeClient from "./amplitude"
import * as google from "./google"
import * as klaviyo from "./klaviyo"
import * as meta from "./meta"
import * as pinterestTag from "./pinterestTag"

const dispatchEvent = (props) => (event) => {
  try {
    event(props)
  } catch (error) {
    console.error("events:", error)
  }
}

const addToCartEvents = [
  google.addToCart,
  klaviyo.addedToCart,
  pinterestTag.addToCart,
  amplitudeClient.addToCart
]

export const addToCart = (
  cartResponse,
  productVariantId,
  quantity,
  currentStore,
  category,
  listName,
  name
) =>
  addToCartEvents.forEach(
    dispatchEvent({
      cartResponse,
      productVariantId,
      name,
      quantity,
      currentStore,
      category,
      listName
    })
  )

const removeFromCartEvents = [google.removeFromCart]

export const removeFromCart = (
  productVariantId,
  name,
  quantity,
  category,
  listName,
  price,
  discount,
  currentStore
) =>
  removeFromCartEvents.forEach(
    dispatchEvent({
      productVariantId,
      name,
      quantity,
      category,
      listName,
      price,
      discount,
      currentStore
    })
  )

const purchaseEvents = [
  google.purchase,
  pinterestTag.purchase,
  klaviyo.placedOrder
]

export const purchase = ({
  order,
  recurlyOrder,
  discountCode,
  cart,
  currentStore,
  email,
  listName
}) =>
  purchaseEvents.forEach(
    dispatchEvent({
      order,
      recurlyOrder,
      discountCode,
      cart,
      email,
      currentStore,
      listName
    })
  )

const initiateCheckoutEvents = [
  google.initiateCheckout,
  klaviyo.startedCheckout,
  amplitudeClient.initiateCheckout,
  meta.initiateCheckout
]

export const initiateCheckout = (
  storedCart,
  currentStore,
  brinkSessionId,
  listName,
  isSubscription = false
) =>
  initiateCheckoutEvents.forEach(
    dispatchEvent({
      storedCart,
      currentStore,
      brinkSessionId,
      listName,
      isSubscription
    })
  )

const viewProductEvents = [
  google.viewProduct,
  klaviyo.viewProduct,
  amplitudeClient.viewProduct
]

export const viewProduct = ({
  sanityProductVariant,
  sanityProduct,
  languageCode,
  prices,
  currentStore,
  index,
  discount,
  listName
}) =>
  viewProductEvents.forEach(
    dispatchEvent({
      sanityProductVariant,
      sanityProduct,
      languageCode,
      prices,
      index,
      discount,
      currentStore,
      listName
    })
  )

const viewCartEvents = [google.viewCart, amplitudeClient.viewCart]

export const viewCart = (cart) =>
  viewCartEvents.forEach(
    dispatchEvent({
      cart
    })
  )

const addShippingEvents = [
  google.addShipping,
  amplitudeClient.addShipping,
  meta.addShipping
]

export const addShipping = (
  cart,
  shippingOption,
  discountCode = null,
  deliveryStep = "country"
) =>
  addShippingEvents.forEach(
    dispatchEvent({
      cart,
      shippingOption,
      discountCode,
      deliveryStep
    })
  )

const viewCollectionsEvents = [google.viewCollection]

export const viewCollection = (products, listName, currentStore) =>
  viewCollectionsEvents.forEach(
    dispatchEvent({
      products,
      listName,
      currentStore
    })
  )

const clickProductEvents = [google.clickProduct]

export const clickProduct = ({
  product,
  listName,
  positionInList,
  currentStore
}) =>
  clickProductEvents.forEach(
    dispatchEvent({
      product,
      listName,
      positionInList,
      currentStore
    })
  )

const identifyEvents = [klaviyo.identify, amplitudeClient.identify]

export const identify = (address) =>
  identifyEvents.forEach(
    dispatchEvent({
      address
    })
  )

const signUpEvents = [
  google.subscribeToNewsletter,
  amplitudeClient.subscribeToNewsletter
]

export const signup = ({ email, pageLocation }) => {
  signUpEvents.forEach(
    dispatchEvent({
      email,
      pageLocation
    })
  )
}

const addPaymentInfoEvents = [
  google.addPaymentInfo,
  amplitudeClient.addPaymentInfo
]

export const addPaymentInfo = (cart, paymentMethod, discountCode) =>
  addPaymentInfoEvents.forEach(
    dispatchEvent({
      cart,
      paymentMethod,
      discountCode
    })
  )

const changeLocationEvents = [google.changeLocation]

export const changeLocation = (store) =>
  changeLocationEvents.forEach(
    dispatchEvent({
      store
    })
  )

const clickProductElementsEvents = [google.clickElement]

export const clickProductElements = (event, pageLocation) =>
  clickProductElementsEvents.forEach(
    dispatchEvent({
      event,
      pageLocation
    })
  )

const clickMenuEvents = [amplitudeClient.clickMenu]

export const clickMenu = () => clickMenuEvents.forEach(dispatchEvent())

const clickAboutEvents = [amplitudeClient.clickAbout]

export const clickAbout = () => clickAboutEvents.forEach(dispatchEvent())

const clickExploreEvents = [amplitudeClient.clickExplore]

export const clickExplore = () => clickExploreEvents.forEach(dispatchEvent())

const clickShopEvents = [amplitudeClient.clickShop]

export const clickShop = () => clickShopEvents.forEach(dispatchEvent())

const startSubscriptionPurchaseEvents = [
  amplitudeClient.startSubscriptionPurchase,
  meta.startSubscriptionPurchase
]

export const startSubscriptionPurchase = (
  productName = null,
  location = "component"
) =>
  startSubscriptionPurchaseEvents.forEach(
    dispatchEvent({
      productName,
      location
    })
  )

const startSubscriptionEvents = [amplitudeClient.startSubscription]
export const startSubscription = (productName) => {
  startSubscriptionEvents.forEach(
    dispatchEvent({
      productName
    })
  )
}

const completeSubscriptionConfigEvents = [
  amplitudeClient.completeSubscriptionConfig,
  meta.completeSubscriptionConfig
]
export const completeSubscriptionConfig = (productName, frequency = null) => {
  completeSubscriptionConfigEvents.forEach(
    dispatchEvent({
      productName,
      frequency
    })
  )
}

const updateSettingEvents = [amplitudeClient.updateSetting]

export const updateSetting = ({ language, location }) =>
  updateSettingEvents.forEach(dispatchEvent({ language, location }))

const addPersonalDetailsEvents = [amplitudeClient.addPersonalDetails]

export const addPersonalDetails = (email, city, country) =>
  addPersonalDetailsEvents.forEach(dispatchEvent({ email, city, country }))

const addDiscountEvents = [amplitudeClient.addDiscount]

export const addDiscount = (code, cart) =>
  addDiscountEvents.forEach(dispatchEvent({ code, cart }))

const selectProductEvents = [amplitudeClient.selectProduct]

export const selectProduct = (name, location) =>
  selectProductEvents.forEach(dispatchEvent({ name, location }))

const toggleReadMoreDescriptionEvents = [
  amplitudeClient.toggleReadMoreDescription
]

export const toggleReadMoreDescription = (productId, productName, isExpanded) =>
  toggleReadMoreDescriptionEvents.forEach(
    dispatchEvent({ productId, productName, isExpanded })
  )

const toggleProductSectionEvents = [amplitudeClient.toggleProductSection]

export const toggleProductSection = (
  productId,
  productName,
  sectionId,
  sectionName,
  isExpanded
) =>
  toggleProductSectionEvents.forEach(
    dispatchEvent({
      productId,
      productName,
      sectionId,
      sectionName,
      isExpanded
    })
  )

const productReviewsAnchorClickedEvents = [
  amplitudeClient.productReviewsAnchorClicked
]

export const productReviewsAnchorClicked = (productId, productName) =>
  productReviewsAnchorClickedEvents.forEach(
    dispatchEvent({ productId, productName })
  )

const productReviewsShowMoreClickedEvents = [
  amplitudeClient.productReviewsShowMoreClicked
]

export const productReviewsShowMoreClicked = (productId, productName, page) =>
  productReviewsShowMoreClickedEvents.forEach(
    dispatchEvent({ productId, productName, page })
  )

const buttonClickedEvents = [amplitudeClient.buttonClicked]

export const buttonClicked = (title, target) =>
  buttonClickedEvents.forEach(dispatchEvent({ title, target }))

const capsuleSelectedEvents = [amplitudeClient.capsuleSelected]

export const capsuleSelected = (productName) =>
  capsuleSelectedEvents.forEach(dispatchEvent({ productName }))
