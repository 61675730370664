import axios from "axios"
import Dinero from "dinero.js"
import createDispatcher from "./createDispatcher"

const dispatcher = createDispatcher("klaviyo")

export const addedToCart = ({
  cartResponse,
  productVariantId,
  quantity,
  currentStore
}) => {
  const _learnq = dispatcher(window._learnq)
  const addedProduct = cartResponse.cartItems.find(
    (p) => p.id === productVariantId
  )
  if (addedProduct.type !== "productVariant") return

  const klaviyoCart = {
    $value: toDinero(
      cartResponse.totalPriceWithDiscount,
      currentStore.currencyUnit
    ).toUnit(),
    AddedItemProductName: addedProduct.name,
    AddedItemProductID: addedProduct.id,
    AddedItemImageURL: addedProduct.imageUrl,
    AddedItemPrice: toDinero(
      addedProduct.price[currentStore.currencyUnit],
      currentStore.currencyUnit
    ).toUnit(),
    AddedItemQuantity: quantity,
    CountryCode: currentStore.countryCode.toLowerCase(),
    ItemNames: cartResponse.cartItems.map((ct) => ct.name),
    CheckoutURL: `${window.location.origin}/checkout`,
    Currency: currentStore.currencyUnit,
    Items: toKlaviyoItems(
      cartResponse.cartItems,
      currentStore.currencyUnit,
      currentStore.countryCode
    )
  }
  const resp = _learnq.push(["track", "Added to Cart", klaviyoCart])
  console.debug("Set klaviyo added to cart response: ", resp)
}

export const identify = ({ address }) => {
  const _learnq = dispatcher(window._learnq)
  const resp = _learnq.push([
    "identify",
    {
      $email: address.email,
      $first_name: address.firstName,
      $last_name: address.lastName
    }
  ])
  console.debug("Set klaviyo identity response: ", resp)
}

export const startedCheckout = ({
  storedCart,
  currentStore,
  brinkSessionId,
  isSubscription
}) => {
  const _learnq = dispatcher(window._learnq)
  const cartProductVariants = storedCart.cartItems.filter(
    (i) => i.type === "productVariant"
  )
  const resp = _learnq.push([
    "track",
    "Started Checkout",
    {
      $event_id: `${storedCart.id}_${new Date().valueOf()}`,
      $value: toDinero(
        storedCart.totalPriceWithDiscount,
        currentStore.currencyUnit
      ).toUnit(),
      CountryCode: currentStore.countryCode,
      ItemNames: cartProductVariants.map((ct) => ct.name),
      CheckoutURL: isSubscription
        ? window.location.href
        : `${window.location.origin}/${currentStore.countryCode}/checkout/?cart=${brinkSessionId}`,
      Items: toKlaviyoItems(
        cartProductVariants,
        currentStore.currencyUnit,
        currentStore.countryCode
      )
    }
  ])
  console.debug("Set klaviyo started checkout response: ", resp)
}

const orderedProducts = (
  billingAddress,
  orderId,
  cartItems,
  currencyUnit,
  _learnq,
  email
) => {
  try {
    const orderedProductEvents = cartItems
      .filter((p) => p.type === "productVariant")
      .map((item) => [
        "track",
        "Ordered Product",
        {
          customer_properties: {
            $email: email,
            $first_name: billingAddress.givenName,
            $last_name: billingAddress.familyName,
            $country: billingAddress.country
          },
          properties: {
            $event_id: orderId,
            OrderId: orderId,
            $value: toDinero(item.price[currencyUnit], currencyUnit).toUnit(),
            ProductID: item.id,
            ProductName: item.name,
            Quantity: item.quantity,
            ImageURL: item.imageUrl,
            Categories: [item.category],
            ProductURL: `${window.location.origin}/product/${item.slug}`
          }
        }
      ])
    const result = orderedProductEvents.map((orderedProductEvent) =>
      _learnq.push(orderedProductEvent)
    )
    console.debug("Set klaviyo orderedProducts response: ", result)
  } catch (error) {
    console.error(error)
  }
}

export const placedOrder = ({
  order,
  recurlyOrder,
  discountCode,
  currentStore,
  email,
  cart
}) => {
  const _learnq = dispatcher(window._learnq)
  const currencyUnit = order ? order.currencyUnit : recurlyOrder.currency
  const orderId = order ? order.id : recurlyOrder.id
  const billingAddress = recurlyOrder
    ? toBillingAddress(recurlyOrder.account, recurlyOrder.address)
    : order.shippingAddress

  const event = [
    "track",
    "Placed Order",
    {
      customer_properties: {
        $email: email ?? billingAddress.email,
        $first_name: billingAddress.givenName,
        $last_name: billingAddress.familyName,
        $phone_number: billingAddress.phone,
        $address1: billingAddress.streetAddress,
        $city: billingAddress.city,
        $zip: billingAddress.postalCode,
        $region: "",
        $country: billingAddress.country
      },
      properties: {
        $event_id: orderId,
        $value: toDinero(
          order ? order.orderAmountWithDiscount : recurlyOrder.total,
          currencyUnit
        ).toUnit(),
        CountryCode: currentStore.countryCode,
        DiscountCode: discountCode || "",
        DiscountValue: toDiscountValue(order, recurlyOrder, currencyUnit),
        ItemNames: cart ? cart.cartItems.map((ct) => ct.name) : [],
        Items: cart
          ? toKlaviyoItems(
              cart.cartItems,
              currencyUnit,
              currentStore.countryCode
            )
          : []
      }
    }
  ]
  const resp = _learnq.push(event)
  console.debug("Set klaviyo placedOrder response: ", resp)
  if (cart) {
    orderedProducts(
      billingAddress,
      orderId,
      cart.cartItems,
      currencyUnit,
      _learnq,
      email
    )
  }
}

const toBillingAddress = (account, address) => ({
  email: account.email,
  firstName: address.first_name,
  lastName: address.last_name,
  phone: address.phone,
  address: address.street1,
  houseNumberOrName: address.street2,
  city: address.city,
  postalCode: address.postal_code,
  region: address.region,
  country: address.country
})

const toDiscountValue = (order, recurlyOrder, currencyUnit) => {
  if (order) {
    return toDinero(order.orderAmount, currencyUnit)
      .subtract(toDinero(order.orderAmountWithDiscount, currencyUnit))
      .toUnit()
  } else {
    return toDinero(recurlyOrder.discount, currencyUnit).toUnit()
  }
}

export const viewProduct = ({
  sanityProductVariant,
  languageCode,
  prices,
  currentStore
}) => {
  if (!sanityProductVariant) return null
  const currencyUnit = currentStore.currencyUnit
  const _learnq = dispatcher(window._learnq)
  const price = prices.find((p) => p.currencyUnit === currencyUnit)?.amount ?? 0
  const resp = _learnq.push([
    "track",
    "Viewed Product",
    {
      ProductName: sanityProductVariant.title,
      ProductID: sanityProductVariant._id,
      LanguageCode: languageCode,
      URL: `${window.location.href}`,
      Price: toDinero(price, currencyUnit).toUnit()
    }
  ])
  console.debug("Set klaviyo Viewed Product response: ", resp)
}

const toKlaviyoAddress = (address) => {
  return {
    email: address.email,
    first_name: address.firstName,
    last_name: address.lastName,
    phone_number: address.phone,
    address1: address.address,
    address2: address.houseNumberOrName || "",
    city: address.city,
    zip: address.postalCode,
    region: "",
    country: address.country
  }
}

const toKlaviyoItems = (items, currencyUnit, countryCode = "en") => {
  return items.map((item) => ({
    ProductID: item.id,
    ProductName: item.name,
    Quantity: item.quantity,
    ItemPrice: toDinero(item.price[currencyUnit], currencyUnit).toUnit(),
    RowTotal: toDinero(item.price[currencyUnit], currencyUnit)
      .multiply(item.quantity)
      .toUnit(),
    Currency: currencyUnit,
    ImageURL: item.imageUrl,
    ProductURL: `${
      window.location.origin
    }/${countryCode.toLowerCase()}/product/${item.slug}`
  }))
}

const toDinero = (amount, currencyUnit) => {
  return new Dinero({ amount: amount, currency: currencyUnit })
}

export const subscribeToNewsletter = async (countryCode, email, listId) => {
  const config = Object.assign(
    {},
    {
      g: listId ?? "WmhKtq",
      email: email
    }
  )

  const body = Object.keys(config).reduce((str, key) => {
    config[key] && str.append(key, config[key])
    return str
  }, new URLSearchParams())

  return (
    await axios({
      url: "https://manage.kmail-lists.com/ajax/subscriptions/subscribe",
      method: "POST",
      headers: {
        "Access-Control-Allow-Headers": "*",
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8"
      },
      data: body
    })
  ).data
}
