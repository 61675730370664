import { navigate } from "gatsby"

export const updateSearchParams = (location, key, value) => {
  const searchParams = new URLSearchParams(location.search)
  searchParams.set(key, value)
  const newSearch = searchParams.toString()
  navigate(`${location.pathname}?${newSearch}`, { replace: true })
}

export const removeCountryFromPath = (path) => {
  return path.replace(/^\/[a-z]{2}/, "")
}
