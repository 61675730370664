import * as amplitude from "@amplitude/analytics-browser"
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import React from "react"
import { CookiesProvider } from "react-cookie"
import { I18nextProvider } from "react-i18next"
import { BrinkContextProvider } from "./src/components/context/BrinkContext"
import { i18nextConfig } from "./src/i18n/locales/config"
import "./src/styles/global.css"

export const wrapPageElement = ({ element, props }) => {
  const { sanityTranslations } = props.pageContext
  return (
    <I18nextProvider i18n={i18nextConfig(sanityTranslations)}>
      <CookiesProvider>
        <BrinkContextProvider>{element}</BrinkContextProvider>
      </CookiesProvider>
    </I18nextProvider>
  )
}

export const shouldUpdateScroll = ({ routerProps }) =>
  routerProps.location.state && routerProps.location.state.disableScrollUpdate
    ? false
    : true

// GTAG disabled default in gatsby gtag plugin.
if (process.env.NODE_ENV === "development") window.gtag = () => {}

Sentry.init({
  dsn: process.env.GATSBY_SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})

if (
  process.env.NODE_ENV === "production" &&
  process.env.GATSBY_AMPLITUDE_CLIENT_KEY
) {
  amplitude.init(process.env.GATSBY_AMPLITUDE_CLIENT_KEY, {
    defaultTracking: {
      attribution: true,
      pageViews: true,
      sessions: true,
      formInteractions: false,
      fileDownloads: false
    },
    pageViewTracking: {
      trackHistoryChanges: "pathOnly"
    },
    plan: {
      source: "comis-website"
    }
  })

  const sessionReplayTracking = sessionReplayPlugin()
  amplitude.add(sessionReplayTracking)
}
