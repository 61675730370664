import { useStaticQuery, graphql } from "gatsby"
export const useBrinkStoreConfig = () => {
  const data = useStaticQuery(graphql`
    query brinkcommerceStores {
      brinkcommerce {
        allStores(from: 0, size: 200) {
          stores {
            countryCode
            created
            languageCode
            currencyUnit
            lastUpdated
            tax
          }
        }
      }
    }
  `)
  const { stores } = data.brinkcommerce.allStores
  return stores
}
