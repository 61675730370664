import * as amplitude from "@amplitude/analytics-browser"
import Dinero from "dinero.js"
import { withoutBreaklines } from "../../../../helpers/string"
import { removeCountryFromPath } from "../../../../helpers/url"
import createDispatcher from "./createDispatcher"

const dispatcher = createDispatcher("amplitude")

const createItem = ({
  name,
  id,
  price,
  category,
  listName,
  quantity,
  index,
  currency,
  discount
}) => {
  return {
    item_name: name,
    item_id: id,
    price: Dinero({
      amount: price,
      currency: currency
    }).toUnit(),
    item_brand: "COMIS",
    item_category: category,
    ...(listName ? { list_name: listName } : {}),
    ...(index || index === 0 ? { index } : {}),
    ...(quantity || quantity === 0 ? { quantity } : {}),
    discount
  }
}

const getProductDiscount = (cart, productId) =>
  cart.discounts.productDiscounts.find((d) => d.id === productId)
    ?.discountAmount[cart.store.currencyUnit]

export const clickMenu = () => {
  const amp = dispatcher(amplitude)
  amp.track("Menu Clicked")
}

export const clickAbout = () => {
  const amp = dispatcher(amplitude)
  amp.track("About Clicked")
}

export const clickExplore = () => {
  const amp = dispatcher(amplitude)
  amp.track("Explore Clicked")
}

export const clickShop = () => {
  const amp = dispatcher(amplitude)
  amp.track("Shop Clicked")
}

export const viewProduct = ({
  sanityProductVariant,
  sanityProduct,
  listName,
  prices,
  index,
  discount,
  currentStore
}) => {
  const amp = dispatcher(amplitude)
  amp.track("Product Viewed", {
    currency: currentStore.currencyUnit,
    product_id: sanityProductVariant
      ? sanityProductVariant._id
      : sanityProduct.variants[0]._id,
    product_name: sanityProductVariant
      ? sanityProductVariant.title
      : sanityProduct.variants[0].title,
    product_price: Dinero({
      amount: prices.find((p) => p.currencyUnit === currentStore.currencyUnit)
        ?.amount,
      currencyUnit: currentStore.currencyUnit
    }).toUnit()
  })
}

export const viewCart = ({ cart }) => {
  const amp = dispatcher(amplitude)
  amp.track("Cart Viewed")
}

export const addToCart = ({ productVariantId, cartResponse, currentStore }) => {
  const addedProduct = cartResponse.cartItems.find(
    (p) => p.id === productVariantId
  )
  const amp = dispatcher(amplitude)
  amp.track("Product Added", {
    currency: currentStore.currencyUnit,
    product_id: productVariantId,
    product_name: addedProduct.name,
    product_price: Dinero({
      amount: addedProduct.price[currentStore.currencyUnit],
      currency: currentStore.currencyUnit
    }).toUnit(),
    sek_product_price: Dinero({
      amount: addedProduct.price["SEK"],
      currency: "SEK"
    }).toUnit()
  })
}

export const initiateCheckout = ({ storedCart }) => {
  const items = storedCart.cartItems.filter(
    (item) => item.type !== "shippingOption"
  )
  const itemsPurchased = items.reduce((acc, item) => (acc += item.quantity), 0)
  const amp = dispatcher(amplitude)
  amp.track("Checkout Started", {
    currency: storedCart.store.currencyUnit,
    items_purchased: itemsPurchased,
    products: items.map((i) => {
      const productDiscount = getProductDiscount(storedCart, i.id)
      return createItem({
        id: i.id,
        name: i.name,
        brand: "COMIS",
        price: i.price[storedCart.store.currencyUnit],
        discount: productDiscount
          ? Dinero({
              amount: productDiscount,
              currency: storedCart.store.currencyUnit
            }).toUnit()
          : 0,
        category: i.category,
        quantity: i.quantity,
        currency: storedCart.store.currencyUnit
      })
    }),
    subtotal: Dinero({
      amount: items.reduce(
        (acc, item) => (acc += item.price[storedCart.store.currencyUnit]),
        0
      ),
      currency: storedCart.store.currencyUnit
    }).toUnit(),
    sek_subtotal: Dinero({
      amount: items.reduce((acc, item) => (acc += item.price["SEK"]), 0),
      currency: "SEK"
    }).toUnit()
  })
}

export const addShipping = ({ cart, shippingOption, deliveryStep }) => {
  const amp = dispatcher(amplitude)
  amp.track("Shipping added", {
    currency: cart.store.currencyUnit,
    delivery_method: shippingOption.label,
    sek_shipping_fee: Dinero({
      amount: cart?.cartItems?.find((item) => item.type === "shippingOption")
        ?.price["SEK"],
      currency: "SEK"
    }).toUnit(),
    shipping_fee: Dinero({
      amount: cart?.cartItems?.find((item) => item.type === "shippingOption")
        ?.price[cart.store.currencyUnit],
      currency: cart.store.currencyUnit
    }).toUnit(),
    delivery_step: deliveryStep
  })
}

export const addPaymentInfo = ({ paymentMethod }) => {
  const amp = dispatcher(amplitude)
  amp.track("Payment Added", {
    payment_method: paymentMethod
  })
}

export const startSubscriptionPurchase = ({ productName, location }) => {
  const amp = dispatcher(amplitude)
  amp.track("Subscription Purchase Started", {
    product_name: productName,
    location
  })
}

export const startSubscription = ({ productName }) => {
  const amp = dispatcher(amplitude)
  amp.track("Subscription Started", {
    product_name: productName
  })
}

export const completeSubscriptionConfig = ({ productName, frequency }) => {
  const amp = dispatcher(amplitude)
  amp.track("Subscription Step Completed", {
    product_name: productName,
    purchase_frequency: frequency
  })
}

export const identify = ({ address }) => {
  if (amplitude) {
    const identifier = new amplitude.Identify()
    if (address.email) {
      identifier.set("email", address.email)
      amplitude.identify(identifier)
    }
  }
}

export const subscribeToNewsletter = ({ email, pageLocation }) => {
  const amp = dispatcher(amplitude)
  amp.track("Registration Completed", {
    page_location: pageLocation === "/" ? "homepage" : pageLocation,
    email
  })
  if (amplitude) {
    const identifier = new amplitude.Identify()
    identifier.set("email", email)
    amplitude.identify(identifier)
    amplitude.setUserId(email)
  }
}

export const updateSetting = ({ language, location }) => {
  const amp = dispatcher(amplitude)
  amp.track("Settings Updated", {
    setting_language: language,
    setting_location: location
  })
}

export const addPersonalDetails = ({ email, city, country }) => {
  const amp = dispatcher(amplitude)
  amp.track("Personal Details Added", {
    city,
    country,
    email
  })
  if (amplitude) {
    const identifier = new amplitude.Identify()
    identifier.set("email", email)
    amplitude.identify(identifier)
    amplitude.setUserId(email)
  }
}

export const addDiscount = ({ code, cart }) => {
  const amp = dispatcher(amplitude)
  amp.track("Promo Code Added", {
    currency: cart.store.currencyUnit,
    promo_code: code,
    promo_value: Dinero({
      amount: cart.totalDiscountAmount,
      currency: cart.store.currencyUnit
    }).toUnit(),
    promo_discount: cart.discounts?.orderRules?.find(
      (r) => r.type === "ORDER_CONTENT"
    )?.discountInPercentage
  })
}

export const purchase = ({ order, klarnaOrder, cart, email, discountCode }) => {
  const amp = dispatcher(amplitude)
  const products = cart.cartItems.filter(
    (item) => item.type !== "shippingOption"
  )
  amp.track("Order Completed", {
    country: cart.store.countryCode,
    currency: cart.store.currencyUnit,
    delivery_method: cart.cartItems?.find((i) => i.type === "shippingOption")
      ?.name,
    items_purchased: cart.cartItems?.filter(
      (item) => item.type !== "shippingOption"
    )?.length,
    order_id: order ? order.id : klarnaOrder.merchant_reference2,
    products: products.map((i) => {
      const productDiscount = getProductDiscount(cart, i.id)
      return createItem({
        id: i.id,
        name: i.name,
        brand: "COMIS",
        price: i.price[cart.store.currencyUnit],
        discount: productDiscount
          ? Dinero({
              amount: productDiscount,
              currency: cart.store.currencyUnit
            }).toUnit()
          : 0,
        category: i.category,
        quantity: i.quantity,
        currency: cart.store.currencyUnit
      })
    }),
    promo_added: !!discountCode,
    promo_value: order
      ? Dinero({
          amount: order.orderAmount - order.orderAmountWithDiscount,
          currency: cart.store.currencyUnit
        }).toUnit()
      : undefined,
    shipping_fee: Dinero({
      amount: cart.cartItems?.find((i) => i.type === "shippingOption")?.price[
        cart.store.currencyUnit
      ],
      currency: cart.store.currencyUnit
    }).toUnit(),
    sek_shipping_fee: Dinero({
      amount: cart.cartItems?.find((i) => i.type === "shippingOption")?.price[
        "SEK"
      ],
      currency: "SEK"
    }).toUnit(),
    subtotal: Dinero({
      amount: cart.totalPrice,
      currency: cart.store.currencyUnit
    }).toUnit(),
    sek_subtotal: Dinero({
      amount: products.reduce((acc, item) => (acc += item.price["SEK"]), 0),
      currency: "SEK"
    }).toUnit(),
    total: Dinero({
      amount: order ? order.orderAmountWithDiscount : klarnaOrder.order_amount,
      currency: order.currencyUnit
    }).toUnit()
  })
}

export const selectProduct = ({ name, location }) => {
  const amp = dispatcher(amplitude)
  amp.track("Product Selected", {
    product_name: withoutBreaklines(name),
    click_location: location,
    page_location: removeCountryFromPath(window.location.pathname)
  })
}

export const toggleReadMoreDescription = ({
  productId,
  productName,
  isExpanded
}) => {
  const amp = dispatcher(amplitude)
  amp.track("Product Description Read More Button Clicked", {
    product_id: productId,
    product_name: productName,
    is_expanded: isExpanded
  })
}

export const toggleProductSection = ({
  productId,
  productName,
  sectionId,
  sectionName
}) => {
  const amp = dispatcher(amplitude)
  amp.track("Product Section Tab Clicked", {
    product_id: productId,
    product_name: productName,
    section_id: sectionId,
    section_name: sectionName
  })
}

export const productReviewsAnchorClicked = ({ productId, productName }) => {
  const amp = dispatcher(amplitude)
  amp.track("Product Reviews Anchor Clicked", {
    product_id: productId,
    product_name: productName
  })
}

export const productReviewsShowMoreClicked = ({
  productId,
  productName,
  page
}) => {
  const amp = dispatcher(amplitude)
  amp.track("Product Reviews Show More Clicked", {
    product_id: productId,
    product_name: productName,
    page
  })
}

export const buttonClicked = ({ title, target }) => {
  const amp = dispatcher(amplitude)
  amp.track("Button Clicked", {
    title,
    target,
    page_url: removeCountryFromPath(
      window.location.pathname + window.location.search
    )
  })
}

export const capsuleSelected = ({ productName }) => {
  const amp = dispatcher(amplitude)
  amp.track("Capsule Selected", {
    product_name: productName
  })
}
