import Dinero from "dinero.js"
import { sha256 } from "../../../../helpers/crypto"
import createDispatcher from "./createDispatcher"

const dispatcher = createDispatcher("google")

const createItem = ({
  name,
  id,
  price,
  category,
  listName,
  quantity,
  index,
  currency,
  discount
}) => {
  return {
    item_name: name,
    item_id: id,
    price: Dinero({
      amount: price,
      currency: currency
    }).toUnit(),
    item_brand: "COMIS",
    item_category: category,
    ...(listName ? { list_name: listName } : {}),
    ...(index || index === 0 ? { index } : {}),
    ...(quantity || quantity === 0 ? { quantity } : {}),
    discount
  }
}

const getProductDiscount = (cart, productId) =>
  cart.discounts.productDiscounts.find((d) => d.id === productId)
    ?.discountAmount[cart.store.currencyUnit]

export const addToCart = ({
  productVariantId,
  quantity,
  category,
  cartResponse,
  currentStore
}) => {
  const dataLayer = dispatcher(window.dataLayer)
  const addedProduct = cartResponse.cartItems.find(
    (p) => p.id === productVariantId
  )
  const productDiscount = getProductDiscount(cartResponse, addedProduct.id)
  dataLayer.push({ ecommerce: null })
  dataLayer.push({
    event: "add_to_cart",
    ecommerce: {
      currency: currentStore.currencyUnit,
      items: [
        createItem({
          name: addedProduct.name,
          id: productVariantId,
          price: addedProduct.price[currentStore.currencyUnit],
          discount: productDiscount
            ? Dinero({
                amount: productDiscount,
                currencyUnit: currentStore.currencyUnit
              }).toUnit()
            : 0,
          currency: currentStore.currencyUnit,
          category,
          quantity
        })
      ]
    }
  })
}

export const removeFromCart = ({
  productVariantId,
  name,
  quantity,
  category,
  price,
  discount,
  currentStore
}) => {
  const dataLayer = dispatcher(window.dataLayer)
  dataLayer.push({ ecommerce: null })
  dataLayer.push({
    event: "remove_from_cart",
    ecommerce: {
      currency: currentStore.currencyUnit,
      items: [
        createItem({
          name,
          id: productVariantId,
          price: price[currentStore.currencyUnit],
          currency: currentStore.currencyUnit,
          category,
          quantity,
          discount: discount
            ? Dinero({
                amount: discount[currentStore.currencyUnit],
                currencyUnit: currentStore.currencyUnit
              }).toUnit()
            : 0
        })
      ]
    }
  })
}

export const viewProduct = ({
  sanityProductVariant,
  sanityProduct,
  listName,
  prices,
  index,
  discount,
  currentStore
}) => {
  const dataLayer = dispatcher(window.dataLayer)
  const category = sanityProduct?.category?.displayName?.en ?? ""
  const price = prices.find(
    (p) => p.currencyUnit === currentStore.currencyUnit
  )?.amount

  const discountAmount = discount?.find(
    (p) => p.currencyUnit === currentStore.currencyUnit
  )
  const items = sanityProductVariant
    ? [
        createItem({
          name: sanityProductVariant.title,
          id: sanityProductVariant._id,
          currency: currentStore.currencyUnit,
          category,
          listName,
          price,
          index,
          discount: discountAmount ? discountAmount.amount : 0
        })
      ]
    : sanityProduct.variants.map((p) =>
        createItem({
          name: p.title,
          id: p._id,
          category,
          listName,
          price,
          index,
          discount: discountAmount
            ? Dinero({
                amount: discountAmount.amount,
                currencyUnit: currentStore.currencyUnit
              }).toUnit()
            : 0
        })
      )
  dataLayer.push({ ecommerce: null })
  dataLayer.push({
    event: "view_item",
    ecommerce: {
      currency: currentStore.currencyUnit,
      items: items
    }
  })
}

export const viewCart = ({ cart }) => {
  const dataLayer = dispatcher(window.dataLayer)
  dataLayer.push({ ecommerce: null })
  dataLayer.push({
    event: "view_cart",
    ecommerce: {
      value: Dinero({
        amount: cart.totalPriceWithDiscount,
        currency: cart.store.currencyUnit
      }).toUnit(),
      ...(cart?.cartItems?.find((item) => item.type === "shippingOption")
        ? {
            shipping: Dinero({
              amount: cart?.cartItems?.find(
                (item) => item.type === "shippingOption"
              )?.price[cart.store.currencyUnit],
              currency: cart.store.currencyUnit
            }).toUnit()
          }
        : {}),
      currency: cart.store.currencyUnit,
      items: cart?.cartItems
        ?.filter((item) => item.type !== "shippingOption")
        .map((i) => {
          const productDiscount = getProductDiscount(cart, i.id)
          return createItem({
            name: i.attribute.displayName.en,
            id: i.id,
            category: i.category,
            price: i.price[cart.store.currencyUnit],
            discount: productDiscount
              ? Dinero({
                  amount: productDiscount,
                  currency: cart.store.currencyUnit
                }).toUnit()
              : 0,
            quantity: i.quantity,
            currency: cart.store.currencyUnit
          })
        })
    }
  })
}

export const viewCollection = ({ products, listName, currentStore }) => {
  const dataLayer = dispatcher(window.dataLayer)
  dataLayer.push({ ecommerce: null })
  dataLayer.push({
    event: "view_item_list",
    ecommerce: {
      currency: currentStore.currencyUnit,
      items: products.map((p, i) => {
        const variant = p.variants[0]
        const { children } = variant
        const { price, discount } = children[0] || {}

        const discountAmount = discount?.find(
          (p) => p.currencyUnit === currentStore.currencyUnit
        )

        return createItem({
          name: p.displayName.en,
          id: p._id,
          category: p.category.displayName.en,
          listName,
          currency: currentStore.currencyUnit,
          price: price.find((p) => p.currencyUnit === currentStore.currencyUnit)
            .amount,
          index: i + 1,
          discount: discountAmount
            ? Dinero({
                amount: discountAmount.amount,
                currency: currentStore.currencyUnit
              }).toUnit()
            : 0
        })
      })
    }
  })
}

export const purchase = async ({
  order,
  recurlyOrder,
  cart,
  email,
  discountCode
}) => {
  const dataLayer = dispatcher(window.dataLayer)
  const purchaseEvent = await toPurchaseEvent(order, recurlyOrder, cart, email)
  const purchaseEventWithDiscount = discountCode
    ? { ...purchaseEvent, coupon: discountCode }
    : purchaseEvent
  dataLayer.push({ ecommerce: null })
  dataLayer.push({
    event: "purchase",
    ecommerce: {
      ...purchaseEventWithDiscount
    }
  })
}

export const addShipping = ({
  cart,
  shippingOption,
  discountCode,
  deliveryStep
}) => {
  const dataLayer = dispatcher(window.dataLayer)
  dataLayer.push({ ecommerce: null })
  dataLayer.push({
    event: "add_shipping_info",
    ecommerce: {
      currency: cart.store.currencyUnit,
      value: Dinero({
        amount: cart.totalPriceWithDiscount,
        currency: cart.store.currencyUnit
      }).toUnit(),
      ...(cart?.cartItems?.find((item) => item.type === "shippingOption")
        ? {
            shipping: Dinero({
              amount: cart?.cartItems?.find(
                (item) => item.type === "shippingOption"
              )?.price[cart.store.currencyUnit],
              currency: cart.store.currencyUnit
            }).toUnit()
          }
        : {}),
      coupon: discountCode,
      shipping_tier: shippingOption.label,
      items: cart?.cartItems
        ?.filter((item) => item.type !== "shippingOption")
        .map((i) => {
          const productDiscount = getProductDiscount(cart, i.id)
          return createItem({
            name: i.attribute.displayName.en,
            id: i.id,
            category: i.category,
            price: i.price[cart.store.currencyUnit],
            discount: productDiscount
              ? Dinero({
                  amount: productDiscount,
                  currency: cart.store.currencyUnit
                }).toUnit()
              : 0,
            quantity: i.quantity,
            currency: cart.store.currencyUnit
          })
        }),
      delivery_step: deliveryStep
    }
  })
}

const toPurchaseEvent = async (order, recurlyOrder, cart, email) => {
  let physicalOrderlines = []
  if (order) {
    physicalOrderlines = order.orderLines.filter((o) => o.type === "physical")
  } else if (recurlyOrder && recurlyOrder.line_items?.length) {
    physicalOrderlines = recurlyOrder.line_items
  }
  if (order) {
    return {
      transaction_id: order.id,
      value: Dinero({
        amount: order.orderAmountWithDiscount,
        currency: order.currencyUnit
      }).toUnit(),
      currency: order.currencyUnit,
      market: cart.store.countryCode,
      tax: Dinero({
        amount: order.orderTaxAmount,
        currency: order.currencyUnit
      }).toUnit(),
      shipping: Dinero({
        amount: order.orderLines.find((item) => item.type === "shipment")
          .totalAmountWithDiscount,
        currency: order.store.currencyUnit
      }).toUnit(),
      user_id: await sha256(email),
      email,
      sha256_email_address: await sha256(email),
      phone_number: order.billingAddress.phone,
      sha256_phone_number: await sha256(order.billingAddress.phone),
      address: {
        first_name: order.billingAddress.givenName,
        sha256_first_name: await sha256(order.billingAddress.givenName),
        last_name: order.billingAddress.familyName,
        sha256_last_name: await sha256(order.billingAddress.familyName),
        street: order.billingAddress.streetAddress,
        city: order.billingAddress.city,
        region: order.billingAddress.region,
        postal_code: order.billingAddress.postalCode,
        country: order.billingAddress.countryCode
      },
      items: physicalOrderlines.map((ol) =>
        createItem({
          id: ol.productId,
          name: ol.name,
          quantity: ol.quantity,
          category: cart.cartItems.find((c) => c.id === ol.productId).category,
          price: ol.totalAmount,
          discount: Dinero({
            amount: ol.totalAmount - ol.totalAmountWithDiscount,
            currency: order.currencyUnit
          }).toUnit()
        })
      )
    }
  } else {
    return {
      transaction_id: recurlyOrder.id,
      value: recurlyOrder.total,
      currency: recurlyOrder.currency,
      tax: recurlyOrder.tax,
      items: physicalOrderlines.map((ol) => ({
        item_id: ol.id,
        item_name: ol.item_code,
        price: ol.unit_amount,
        item_brand: "COMIS",
        item_category: ol.type,
        quantity: ol.quantity,
        discount: ol.discount
      }))
    }
  }
}

export const initiateCheckout = ({ storedCart }) => {
  const shippingOption = storedCart?.cartItems?.find(
    (item) => item.type === "shippingOption"
  )
  const dataLayer = dispatcher(window.dataLayer)
  dataLayer.push({ ecommerce: null })
  dataLayer.push({
    event: "begin_checkout",
    ecommerce: {
      currency: storedCart.store.currencyUnit,
      value: Dinero({
        amount: storedCart.totalPriceWithDiscount,
        currency: storedCart.store.currencyUnit
      }).toUnit(),
      ...(shippingOption
        ? {
            shipping: Dinero({
              amount: shippingOption.price[storedCart.store.currencyUnit],
              currency: storedCart.store.currencyUnit
            }).toUnit()
          }
        : {}),
      items: storedCart.cartItems
        .filter((item) => item.type !== "shippingOption")
        .map((i) => {
          const productDiscount = getProductDiscount(storedCart, i.id)
          return createItem({
            id: i.id,
            name: i.name,
            brand: "COMIS",
            price: i.price[storedCart.store.currencyUnit],
            discount: productDiscount
              ? Dinero({
                  amount: productDiscount,
                  currency: storedCart.store.currencyUnit
                }).toUnit()
              : 0,
            category: i.category,
            quantity: i.quantity,
            currency: storedCart.store.currencyUnit
          })
        })
    }
  })
}

export const addPaymentInfo = ({ cart, paymentMethod, discountCode }) => {
  const dataLayer = dispatcher(window.dataLayer)
  dataLayer.push({ ecommerce: null })
  dataLayer.push({
    event: "add_payment_info",
    ecommerce: {
      currency: cart.store.currencyUnit,
      value: Dinero({
        amount: cart.totalPriceWithDiscount,
        currency: cart.store.currencyUnit
      }).toUnit(),
      shipping: Dinero({
        amount: cart.cartItems.find((item) => item.type === "shippingOption")
          .price[cart.store.currencyUnit],
        currency: cart.store.currencyUnit
      }).toUnit(),
      coupon: discountCode,
      payment_type: paymentMethod,
      items: cart.cartItems
        .filter((item) => item.type !== "shippingOption")
        .map((i) => {
          return createItem({
            id: i.id,
            name: i.name,
            brand: "COMIS",
            price: i.price[cart.store.currencyUnit],
            discount: Dinero({
              amount: i.discount[cart.store.currencyUnit],
              currency: cart.store.currencyUnit
            }).toUnit(),
            quantity: i.quantity,
            currency: cart.store.currencyUnit
          })
        })
    }
  })
}

export const clickProduct = ({
  product,
  listName,
  positionInList,
  currentStore
}) => {
  const dataLayer = dispatcher(window.dataLayer)
  const variant = product.variants[0]
  const { children } = variant
  const { price, discount } = children[0] || {}

  const discountAmount = discount?.find(
    (p) => p.currencyUnit === currentStore.currencyUnit
  )
  dataLayer.push({ ecommerce: null })
  dataLayer.push({
    event: "select_item",
    ecommerce: {
      currency: currentStore.currencyUnit,
      items: [
        createItem({
          id: product._id || product.id,
          name: product.title || product.displayName.en,
          category: product.category.displayName.en,
          currency: currentStore.currencyUnit,
          listName: listName,
          index: positionInList,
          price: price.find((p) => p.currencyUnit === currentStore.currencyUnit)
            .amount,
          discount: discountAmount
            ? Dinero({
                amount: discountAmount.amount,
                currency: currentStore.currencyUnit
              }).toUnit()
            : 0
        })
      ]
    }
  })
}

export const changeLocation = ({ store }) => {
  const dataLayer = dispatcher(window.dataLayer)
  dataLayer.push({ event: "market", market: store.countryCode })
}

export const clickElement = ({ event, pageLocation }) => {
  const dataLayer = dispatcher(window.dataLayer)
  dataLayer.push({ event: event, pageLocation: pageLocation })
}

export const subscribeToNewsletter = async ({ email, pageLocation }) => {
  const dataLayer = dispatcher(window.dataLayer)
  dataLayer.push({
    event: "newsletter_signup",
    user_id: await sha256(email),
    page_location: pageLocation === "/" ? "homepage" : pageLocation
  })
}
